@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700;800;900&amp;display=swap');

/* Swiper Slider start */
.swiper-slide {
  height: auto !important;
  align-self: stretch !important;
}


.swiper-wrapper {
  gap: 20px;
}

span.swiper-notification {
  display: none;
}

a:hover {
  color: #777AE5;
}

.kaartje {
  background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.08) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.06);
  border: 1px solid rgba(175, 147, 255, 0.50);
  color: white;
  border-radius: 4px;
}

/* Swiper Slider end */

/*Global Css*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgba(175, 147, 255, 0.5) rgba(26, 13, 47, 0.5);
}

*::-webkit-scrollbar {
  width: 4px;  /* Even thinner scrollbar */
  height: 4px; /* For horizontal scrollbars */
}

*::-webkit-scrollbar-track {
  background: rgba(26, 13, 47, 0.5);
  border-radius: 12px;
}

*::-webkit-scrollbar-thumb {
  background: rgba(175, 147, 255, 0.5);
  border-radius: 12px;
  transition: all 0.3s ease;
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(175, 147, 255, 0.8);
}

body {
  font-family: 'Public Sans', sans-serif;
  background-color: #1111119a !important;
}

.custom-container:before,
.custom-container:after {
  display: none !important;
}

.custom-container {
  max-width: calc(100% - 200px);
  /* padding: 0 100px 0 150px; */
  /* max-width: 740px !important; */
  width: 100%;
  margin: auto;
  /* padding: 0 15px; */
}

@media screen and (max-width: 768px) {
  .custom-container {
    max-width: calc(100%);

  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.row {
  margin-right: -0px !important;
  margin-left: -0px !important;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

footer .footer-service ul.row {
  gap: 1px;
}

/* a.text-gray.Terms {
	margin-left: 31px;
} */
.tab-content {
  display: none;
}

.tab-active {
  display: block;
}

/*Alignment Css*/

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.align-items-center {
  align-items: center;
}

/*Color Css*/

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.text-gray {
  color: #b2a6ad;
}

.text-light-gray {
  color: #e5dfe2;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.text-color {
  color: #e5dfe2;
}

/*Button Css*/

.button {
  color: #fff;
  text-align: center;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid var(--night-color-secondary-600, #8932f6);
  background: linear-gradient(180deg, #8932f6 0%, #a15bf9 100%);
  cursor: pointer;
  display: flex;
  width: 140px;
  height: 56px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background: linear-gradient(180deg, #8932f6 0%, #a25bf9 0%);
  color: white;
  transition: all 0.5s;
}

/*Custom Css*/

h1 {
  color: #fff;
  text-align: center;
  font-family: Public Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  /* 112.5% */
  letter-spacing: -1px;
  line-height: 72px;
  width: 50%;
  margin: auto !important;
  margin-top: 80px;
}

h2 {
  font-family: Public Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  /* 112.5% */
  letter-spacing: -1px;
}

h3 {
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  padding-bottom: 8px;
}

h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}

h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding: 5px 0px;
}

h6 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #262123 inset !important;
  -webkit-text-fill-color: #b2a6ad !important;
}

/*Header*/

header {
  padding: 64px 100px;
  position: relative;
  z-index: 9999;
}

header .header-menu ul.menu.row {
  gap: 32px;
}

header .menu a {
  transition: all 0.5s;
}

header .menu a:hover {
  opacity: 0.7;
}

header .navigation-menu {
  display: none;
}

.arrow img.logo {
  width: 40px;
  height: 40px;
}

.logo {
  height: 33px;
  width: 80px;
}

.logo-img {
  text-align: center;
  padding-top: 64px;
}

.hero-search-bar {
  transition: all 0.3s;
  position: relative;
}

.slick-track {
  width: 2761px !important;
}

.toolt span {
  color: #eeeefc;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 2px 4px;
  border-radius: 4px;
  background: #5559df;
}

.hero-section form.hero-form input {
  padding: 21px 42px;
  width: 60%;
  margin: auto;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  border-radius: 50px;
  color: #80737A;
  transition: all 0.5s;
  outline: none;
  border: 2px solid transparent;
  /* background-image: linear-gradient(#f2ebef, #f2ebef), linear-gradient(315deg, #8932F6 0%,#F971BA 100%); */
  background-origin: border-box;
  background-clip: padding-box, border-box;
  animation: bg-hue 2s linear infinite;
  opacity: .9;
}

.hero-section form.hero-form input::placeholder {
  color: #7f7a81;
}

.hero-section form.hero-form input:focus {
  background-image: linear-gradient(#f2ebef, #f2ebef),
    linear-gradient(315deg, #8932f6 0%, #f971ba 100%) !important;
  opacity: 1 !important;
}

.hero-section form.hero-form input:hover {
  background-image: linear-gradient(#fefefe, #fefefe),
    linear-gradient(315deg, #8932f6 0%, #f971ba 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  animation: bg-hue 2s linear infinite;
}

div#root {
  /*background: linear-gradient( 179deg, rgb(87 17 126) 3%, rgb(55 0 102) 21%, rgb(13 11 12) 44% );  OLD gradient shade changed on 09-11-2023 */
  background-position: center;
  background-size: cover;
  z-index: 1;
  width: 100%;
}

.hero-section {
  background-position: calc(80% - 0px) 40%;
  background-size: cover;
  z-index: 10000;
  width: 100%;
  background-image: linear-gradient(to bottom, #420c65, #320f53, #250f41, #1a0d2f, #11041e);
  backdrop-filter: 250px;
  /* background: linear-gradient(179deg, rgb(87 17 126) 3%, rgb(55 0 102) 21%, #12041f, 44%); */

  /* background: linear-gradient( 179deg, rgb(87 17 126) 21%, rgb(55 0 102) 55%, #12041f, 95% ); */
  /* background: linear-gradient( 179deg, rgb(87 17 126) 6%, rgb(55 0 102) 64%, 12041f 100% ); */
  /* background: linear-gradient(0deg,hsl(330deg 8.33% 4.71%), hsl(272.55deg 102.16% 20%), hsl(278.71deg 75.61% 28.12%)); */
}

.hero-section-single {
  background-position: calc(80% - 0px) 40%;
  background-size: cover;
  z-index: 1;
  width: 100%;
  /* background-image: linear-gradient(to bottom, #420c65, #320f53, #250f41, #1a0d2f, #11041e); */

  /* background: linear-gradient( 179deg, rgb(87 17 126) 2%, rgb(55 0 102) 21%, rgb(13 11 12) 58% ); */
}

.hero-section form.hero-form input:hover {
  background-color: white;
}

.hero-form:hover svg path {
  stroke: #8932f6;
}

.hero-section form.hero-form {
  margin: auto;
  text-align: center;
  margin-top: 31px;
  position: relative;
  width: 634px;
  width: 92%;
  display: flex;
  /* width: 634px; */
  height: 64px;
  align-items: center;
  gap: 12px;
}

.hero-section form.hero-form button {
  position: absolute;
  z-index: 1;
  left: 29px;
  top: 64%;
  transform: translateY(-43%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #665c61;
}

.hero-section form.hero-form:hover button #search_icon_color1 {
  stop-color: #8932f6;
}

.hero-section form.hero-form:hover button #search_icon_color2 {
  stop-color: #f971ba;
}



/*******searchbar modal**/
.modals {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  transition: height 0.1s ease-out;
  z-index: 9999999;
}

.Modal-Body .modal-error {
  color: red;
  margin-bottom: 15px;
}

.modal-contents {
  margin-top: 47px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.closes {
  top: 16px;
  right: 19px;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  z-index: 9999;
  color: #fff !important;
  opacity: 1;
  font-size: 17px;
  font-weight: 100;
}

.search-information-box.destop.active {
  display: block !important;
}

.search-information-box.mobile.active {
  display: none !important;
}

#modals {
  display: none !important;
}

/***search modal End**/
.row:after,
.row:before {
  display: none !important;
  content: ' ';
}

form input {
  outline: none;
}

.hero-section .tabs {
  /* max-width: 33%; */
  padding-bottom: 24px;
  padding-top: 24px;
}

h2.text-white.ontdek {
  padding-bottom: 16px;
  color: #fff;
  font-family: Public Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  /* 112.5% */
  letter-spacing: -1px;
  margin: 0;
}

.hero-section .tabs img {
  text-align: center;
  margin: auto;
  display: block;
}

.tab-link.active h5 {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, #8932f6 0%, #f971ba 100%) 1;
}

.hero-section .search-information-box {
  width: 50%;
  z-index: 1000000000;
  margin: auto;
  border-radius: 12px;
  margin-top: 19px;
  display: none;
  position: relative;
}

.hero-section .hero-search-bar:focus .search-information-box,
.hero-section .hero-search-bar .search-information-box.active {
  display: block;
  position: absolute;
  z-index: 99999999;
  left: 0;
  right: 0;
}

.hero-section .search-information-service {
  cursor: pointer;
  padding: 0px 24px 16px 24px;
  background-color: white;
}

.hero-section .social-club-information {
  border-radius: 0px 0px 8px 8px;
}

.hero-section .serach-info {
  border-radius: 8px 8px 0px 0px;
  position: relative;
  z-index: 100;
}

.search-information-service.social-club-information {
  padding-bottom: 24px;
}

/* .hero-section .search-information-service h6 {

} */

.search-information-box .main-box {
  position: relative;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 8px;
}

.search-title {
  border-radius: 8px 8px 0px 0px;
  padding: 20px 24px 21px 24px;
  background-color: white;
  color: #1a0d2f;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 125% */
}

.search-information-content h5 {
  font-size: 16px;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #262123;
}

.hero-section .search-information-image svg {
  margin: 8px 16px 0px 0px;
}

.hero-section .search-information-content p {
  color: #595155;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}

.hero-section .search-information-service:hover h5 {
  color: #8932f6;
}

.hero-section .search-information-service:hover p {
  color: #635d60;
}

.checkbox {
  margin-top: 17px;
  margin-bottom: 24px;
  display: flex;
}

.main-box {
  margin-top: 8px;
}

/*zoek-evenementen*/
.tab-a.active-a h5 {
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(90deg, #8932f6 0%, #f971ba 100%) 1;
}

.zoek-evenementen {
  /* background-color: #0d0b0c; */
  padding: 0px 0px 40px 0px;

}

.zoek-evenementen .tab-menu ul {
  display: flex;
  flex-wrap: wrap;
  gap: 21px;

}

.zoek-evenementen .tab-menu ul li {
  width: calc(33.33% - 14px);
}

.zoek-evenementen .tab-menu ul li a {
  width: 100%;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.4);
  background: transparent;
  padding: 24px 0 24px 0;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  border-radius: 8px;
  position: relative;
  transition: all 0.5s;
  z-index: 0;
}

.zoek-evenementen .tab-menu ul li a:hover {
  background: linear-gradient(90deg, #8932f6 0%, #f971ba 100%);
}

.zoek-evenementen .tab-menu ul li a:after {
  content: '';
  height: 120px;
  /*  height: calc(100% - 2px); */
  width: calc(100% - 2px);
  border-radius: 8px;
  display: block;
  background: #262123;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.event-content.text-white.doubles {
  /* background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.08) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.06); */
  /* border: 1px solid rgba(175, 147, 255, 0.20); */
  width: 48.5%;
  /*    height: 141px; */
  border-radius: 15px;
  transition: all 0.5s;
}

@media screen and (max-width:768px) {
  .event-content.text-white.doubles {
    width: 100%;
  }
}

.rotterdam-tab h5.text-white,
.amsterdam-tab h5.text-white {
  color: #FFF;
  text-align: center;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.row.double {
  display: flex !important;
  gap: 20px !important;
  flex-wrap: wrap;
}

.zoek-evenementen .tab-menu ul li a:hover:after {
  background-color: #1a1618 !important;
}

.zoek-evenementen .tab-content {
  margin-top: 24px;
}

.zoek-evenementen .tab {
  display: block;
}

.zoek-evenementen .tab-header {
  margin-top: 40px;
}

.zoek-evenementen .tab-active {
  display: block;
}

.zoek-evenementen .tab-menu {
  margin-bottom: 35px;
}

.zoek-evenementen .tab-menu h6 {
  color: #f2ebef;
  padding-top: 10px;
}

.zoek-evenementen .tab-menu img {
  display: block;
  margin: auto;
  width: 10%;
}

.zoek-evenementen .event-content h6 {
  color: #e5dfe2;
}

.zoek-evenementen .event-content span {
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  background-color: #5559df;
  color: #eeeefc;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  /* padding: 2px 4px; */
  align-items: center;
  /* margin: 0 3px; */
}

.card-divider {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, #8932f6 0%, #f971ba 100%);
  border-image-slice: 1;
  padding-bottom: 40px;
}

.date {
  color: #fff;
  text-align: right;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

li.event.date .text-white {
  color: #f2ebef;
  font-family: Public Sans;
  font-size: 18px;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

li.event.date {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px;
  flex-wrap: wrap;
  border-radius: 7px;
  background: rgba(247, 242, 253, 0.10);
  /* background: rgba(243, 234, 254, 0.1); */
}

li.event.date img {
  position: relative;
  top: 3px;
}

.event-information-box .row {
  gap: 8px;
  margin: 0 24px;
}

.don_img {
  /* width: 143px !important; */
  height: 141px;
  border-radius: 16px 0px 0px 16px;
}

.zoek-evenementen .event-box {
  margin-top: 20px;
  /* 	margin-top: 16px;
    padding: 24px 20px;
    border-radius: 12px;
    background:  #262123; */
}

.event-box .row {
  gap: 20px;
}

h3.Vrijdag {
  margin-top: 6px;
  margin-bottom: 4px;
}

.event-content {
  width: 100%;
  transition: all 0.5s;
  border: 1px solid rgba(175, 147, 255, 0.50) !important;
  padding: 24px 20px;
  border-radius: 8px !important;
  background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.08) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.06);
}

.event-content:hover {
  background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.15) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.13);
}

.zoek-evenementen .event-image {
  width: 19%;
  height: 141px;
}

.zoek-evenementen .event-image img {
  width: 100%;
  height: 141px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.zoek-evenementen .event-content h5 {
  font-weight: 600;
  white-space: nowrap;
  /* Prevent text from wrapping to the next line */
  overflow: hidden;
  /* Hide any overflowing text */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) when text overflows */
  width: 100%;
  /* padding-top: 20px; */
}

.zoek-evenementen span.tooltip-button {
  position: relative;
  display: flex;
  z-index: 0;
  transition: all 0.5s;
}

.zoek-evenementen .tooltipss {
  position: relative;
  width: fit-content;
  z-index: 0;
  display: flex;
  margin-top: 14px;
}

/* .tooltipss:hover .tooltip-content {
    visibility: visible;
    display: block;
} */
.Sould.Out span {
  color: #fdeaeb;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  padding: 2px 4px;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: #ef2c38;
  width: fit-content;
  margin-top: 12px;
}

.zoek-evenementen p.tooltip-content {
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 12px;
  border-radius: 8px;
  background: #eeeefc;
  width: 176px;
  max-width: 176px;
  visibility: hidden;
  color: #5559df;
  display: none;
  position: absolute;
  left: 199px;
  top: 50%;
  transform: translateY(-50%);
  transition: all linear 0.3s;
}

/* .tooltipss:hover .tooltip-content.Vriendenticket {
    visibility: visible;
    display: block;
} */
.zoek-evenementen p.tooltip-content.Vriendenticket {
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 12px;
  border-radius: 8px;
  background: #eeeefc;
  width: 176px;
  max-width: 176px;
  visibility: hidden;
  color: #5559df;
  display: none;
  position: absolute;
  left: 170px;
  top: 50%;
  transform: translateY(-50%);
  transition: all linear 0.3s;
}

.tooltips {
  position: relative;
}

/* Style text tooltip */
.tooltipText {
  background-color: #fff;
  position: absolute;
  top: -153%;
  left: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  opacity: 0;
  transition: all 0.5s;
}

/*  Membuat dan style tip*/
.tooltipText::after {
  content: '';
  border-width: 8px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 39%;
  left: -15px;
  /* margin-left: 24%; */
  transform: rotate(90deg);
}

/* Style Icon */
.tooltip-button {
  background-color: rgba(168, 164, 164, 0.3);
  padding: 10px 15px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
}

span.tooltipText.wa {
  padding: 8px;
  width: 184px;
  max-width: 184px;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 12px;
  border-radius: 8px;
  background: #eeeefc;
  color: #5559df;
  z-index: 999;
  text-align: left;
}

h5.Charlee {
  color: #fff;
}

span.tooltipText.wa.Vriendenticket {
  background-color: #fff;
  position: absolute;
  top: -42px;
}

/* Hover text tooltip */
.tooltips:hover .tooltipText {
  opacity: 1;
  transform: translateX(10px);
}

.zoek-evenementen .tooltip img {
  display: none;
}

h6.zero {
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

img.zero_img {
  height: 40px;
  width: 45px !important;
}

.justify-content-between {
  justify-content: space-between;
  width: 100%;
}

.donderdag {
  color: #fff;
  font-family: Public Sans;
  font-size: 22px;
  font-style: normal;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 26px;
  /* 118.182% */
  letter-spacing: -0.5px;
  margin-bottom: 4px;
  padding: 0;
}

h5.Charlee {
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
}

h6.text-light-gray {
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.tooltip span {
  color: #5559df;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.containerss {
  max-width: 752px;
  width: 100%;
  margin: auto;
}

/*Footer*/

footer {
  padding: 120px 0px;
  background-color: #000;
  color: #fff;
  border-top: 1px solid #665c61;
}

footer .newsletter,
.footer-service {
  width: 40%;
}

footer .newsletter-form,
.footer-description {
  width: 60%;
}

footer .footer-service ul.row {
  gap: 30px;
}

.newsletter h6.text-gray {
  color: #b2a6ad;
  /* text-align: center; */
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

h3.join-newsletter {
  color: #f2ebef;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Public Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.5px;
}

footer input {
  width: 67%;
  color: #f2ebef;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 18px 24px;
  border-radius: 8px;
  border: 1px solid #1a0d2f;
  background: #0d0b0c;
  padding: 18px 24px;
  border-radius: 8px;
  border: 1px solid #1a0d2f;
  background: #0d0b0c;
  color: #f2ebef;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

a {
  text-decoration: none !important;
}

h6.text-end.text-gray {
  color: #b2a6ad;
  /* text-align: center; */
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

footer input::placeholder {
  color: #f2ebef;
}

footer form button {
  margin-left: 10px;
  background-color: transparent;
  cursor: pointer;
  color: #f2ebef;
  text-align: center;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid #ccc2c7;
  cursor: pointer;
}

footer form button:hover {
  border: 1px solid #fff;
}

footer .footer-top {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(90deg, #8932f6 0%, #f971ba 100%);
  border-image-slice: 1;
  padding-bottom: 50px;
}

.border-gradient {
  border: 1px solid;
  border-image-source: linear-gradient(90deg, #8932f6 0%, #f971ba 100%);
  border-image-slice: 1;
  border-radius: 8px !important;
  overflow: hidden !important;
}

footer .footer-bottom {
  padding-top: 50px;
}

.footer-bottom a {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

h3.join-news {
  color: #f2ebef;
  font-family: Public Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.5px;
}

/*Single-Event-Page*/

/*Event-Hero-Section*/

.event-hero-section {
  background-image: url(./assets/images/single-event-hero-banner-img.png);
  background-position: 0%;
  background-size: cover;
  z-index: 1;
  width: 100%;
  padding-top: 150px;
  margin-top: -170px;
  display: flex;
}

.event-img.text-center img {
  border-radius: 24px;
  width: 70%;
  max-height: 500px;
  object-fit: cover;
  margin: 0 auto;
}

img.Illustration {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.container-single {
  padding: 75px 150px;
}

.arrow {
  width: 47%;
}

.event-hero-section .event-img img {
  border-radius: 24px;
  width: 100%;
  max-height: 350px;
}

.event-hero-section h1 {
  width: 100%;
  padding-top: 0px;
}

h1.text-center.text-white {
  color: #FFF;
  text-align: center;
  font-family: Public Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -1px;
  /* margin-top: 64px !important; */
  text-transform: capitalize;
}


h1.text-center.text-whites {
  color: #fff;
  font-family: Public Sans;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 62px;
  letter-spacing: -1px;
  width: 100%;
  margin-top: 32px !important;
  margin-bottom: 24px !important;
}

a.buttons {
  display: flex;
  width: 140px;
  height: 56px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #ccc2c7;
  background: transparent;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
}

.event-hero-section .row .event {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.2);
  padding: 6px 8px;
  margin-top: 15px;
}

.event-hero-section .row .event a {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: default;
}

.event-hero-section .row .event a img {
  margin-right: 2px;
}

.event-price-box {
  border-radius: 8px;
  background: #0A0315;
  padding: 24px;
  margin-top: 24px;
  border-radius: 8px;
}

.event-price-box>.soldout {
  align-items: center;
}


.event-price-box>.soldout>.price-box h3 {
  padding: 0;
}

.price-box.text-white h3 {
  padding-bottom: 8px;
  color: #e7d6fd;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.5px;
}

.price-box.text-white h6 {
  color: #f2ebef;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.price-button .button {
  width: 200px;
  color: #fff;
  text-align: center;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  height: 56px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--night-color-primary-600, #8932f6);
  background: linear-gradient(180deg, #8932f6 0%, #a15bf9 100%);
}

.price-button .button[disabled] {
  /* background: gray;
  border-color: transparent */
  opacity: 0.5;
}

.event-hero-section .events-box .vriendenticket {
  padding-top: 48px;
}

.event-information-box .row {
  gap: 8px;
}

.event-hero-section .multipan-event-box {
  padding-top: 16px;
}

.multipan-event-box {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
}

.event-hero-section .events-box img {
  margin-right: 6px;
}

.multipan-event-box img {
  padding-top: 4px;
}

.events-image {
  width: 25px;
}

.events-content {
  flex-grow: 1;
  width: calc(100% - 35px);
}

.events-content.text-white {
  font-weight: 600;
}

p.text-light-gray.event-content {
  color: #e5dfe2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.events-content h5.text-white {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.events-content p.event-content {
  width: 90%;
  line-height: 26px;
}

/* .events-box {
  margin-left: 22px;
  margin-right: 22px;
  margin-top: 48px;
} */

/*event-information-form*/

.event-information-form {
  /* background: rgba(255, 255, 255, 0.05); */
  background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.08) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.06);
  border: 1px solid rgba(175, 147, 255, 0.20);
  padding: 32px 24px;
  margin-top: 48px;
  border-radius: 12px;
  /* margin-left: 22px;
    margin-right: 22px; */
  margin-bottom: 140px;
}

.event-information-form h3.form-heading {
  font-size: 26px;
  line-height: 32px;
  padding-bottom: 24px;
}

.first-name.event-sec-forms {
  display: flex;
}

.col-md-6 {
  width: 50%;
  padding: 0 5px;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 1px;
  opacity: 1;
}

.col-md-6:first-child {
  margin-right: 8px;
}

.event-information-form .event-price-box {
  margin: 0;
}

.event-information-form form input {
  color: white !important;
}

.event-information-form form input,
select#geslacht {
  width: 100%;
  opacity: 1;
  transition: all 0.5s;
  width: 100%;
  opacity: 1;
  transition: all 0.5s;
  font-family: Test National 2;
  font-family: Test National 2;
  color: #eeeeee;
  font-family: Test National 2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  height: 44px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid rgba(175, 147, 255, 0.40);
  background: #130826;
}

span.tooltip-button svg {
  margin-left: 3px;
}

.event-information-form form .checkbox input {
  appearance: none;
  -webkit-appearance: none;
  width: 19px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 1px 8px 0 0;
  border: 2px solid #302640;
  background: #130826;
  /*background-color: rgb(38 33 35);
    border: 2px solid #51494d;*/
  border-radius: 4px;
  position: relative;
}

.event-information-form form .checkbox input:focus {
  outline: none;
}

.event-information-form form .checkbox input[type='checkbox']:checked::after {
  content: '';
  height: 5px;
  width: 9px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: none;
  position: absolute;
  top: 5px;
  left: 2px;
  transform: rotate(-45deg);
}

li.event.date img {
  height: 25px;
  width: 25px;
}

input#date::-webkit-calendar-picker-indicator {
  display: none;
}

.event-information-form form .checkbox input[type='checkbox']:checked::after {
  display: block;
}

.event-information-form form .checkbox label {
  color: rgba(178, 166, 173, 2);
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-left: 0;
}

.event-information-form form .checkbox a {
  text-decoration: revert !important;
  color: rgba(178, 166, 173, 2);
  opacity: 1;
  font-weight: 400;
  font-size: 16px;
}

.event-information-form form input::placeholder {
  color: rgba(178, 166, 173, 2);
}

.event-information-form form input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

select#geslacht:hover {
  border: 1px solid #f2ebef;
}

.event-form-sec input.sin:hover {
  border: 1px solid #f2ebef;
  background-color: #130826;
}

/* .event-form-sec .event-sec-form:hover {
    background: linear-gradient(90deg, #8932F6 0%, #F971BA 100%);
}

 .col-md-6:hover{
	background: linear-gradient(90deg, #8932F6 0%, #F971BA 100%);

}*/
.event-form-sec .event-sec-form {
  border-radius: 4px;
  padding: 1px;
  margin-bottom: 12px;
  display: flex;
  position: relative;
}

.event-form-sec .event-sec-form span {
  position: absolute;
  top: 10px;
  left: 34px;
  color: #b2a6ad;
  font-weight: 100;
  font-size: 17px;
}

/*******Login******/
p.protected {
  color: #fff;
  font-family: Public Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
}

img.protected {
  width: 29px;
}

.logo-log {
  height: 33px;
  width: 80px;
  margin: 0 auto;
}

.login {
  /*background-image: url(../new-image/login.jpg);*/
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%),
    linear-gradient(118deg,
      #03001e 7.55%,
      #6203c0 40.43%,
      #c838ec 76.75%,
      #fdeff9 101.78%);
}

div#namelocation {
  color: #ef2c38;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 4px;
}

img.danger {
  position: relative;
}

input.btn.btn-primarys {
  color: #fff;
  text-align: center;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.error-border {
  border: 1px solid #ef2c38 !important;
}

.btn-primarys {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #8932f6;
  background: linear-gradient(180deg, #8932f6 0%, #a15bf9 100%);
  width: 100%;
  margin-top: 24px;
  cursor: pointer;
}

.premium-button {
  border: none !important;
  background: rgb(119, 33, 229) !important;
  background: linear-gradient(180deg, rgba(119, 33, 229, 1) 0%, rgba(85, 2, 191, 1) 100%) !important;
}

.white-button {
  border: none !important;
  background: #fff !important;
  color: #000 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 8px;
  font-size: 16px;
  padding: 12px 24px;
  font-weight: 600;
}

.leaflet-control-attribution {
  display: none !important;
}

.social-proof-card {
  background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.08) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.06);
  border-radius: 8px;
  border: 1px solid rgba(173, 122, 238, 0.5);  /* #AD7AEE with 50% opacity */
}

#map {
  height: 100vh;
}

.leaflet-container {
  height: 400px;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.btn-primarys:focus {
  outline: none !important;
}

.btn-primarys:hover {
  color: #fff;
}

.login_form {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: relative;
}

.lg {
  width: 399px;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin: auto;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  z-index: 999;
}

.login-text {
  width: 354px;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin: auto;
  z-index: 999;
  margin-top: 20px;
  border-radius: 4px;
  color: #fff;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 133.333% */
}

img.mice {
  height: 24px;
  width: 24px;
  position: relative;
  margin-top: 20px;
  margin-right: 8px;
}

.community {
  color: #fff;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline !important;
}

input#validationCustom01 {
  width: 100%;
  display: flex;
  height: 44px;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 0.5px solid #665c61;
  background: #262123;
  /* margin-bottom: 8px; */
}

button.btn.btn-dropdown {
  padding: 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Public Sans;
  font-size: 16px;
  gap: 4px;
  display: flex;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  border: 1px solid transparent;
  /*2px solid transparent*/
  /* background: #1f0a42; */
  border: 1px solid rgba(175, 147, 255, 0.20);
}

.side img {
  position: relative;
  /* top: -2px; */
}

button.btn.btn-dropdown>span:nth-child(2) {
  flex: none !important;
}

button.btn.btn-dropdown>.chakra-button__icon:first-child {
  margin-inline-end: 0;
}

button.btn.btn-dropdown>.chakra-button__icon:last-child {
  margin-inline-start: 0;
}

button.btn.btn-dropdown[aria-expanded='true'] {
  border: 1px solid #80737a !important;
  background: #5a24b1;
}

.selected-button {
  border: 1px solid #80737a;
}

button.nextButton.btn.btn-dropdown.dropdown-toggle img {
  position: relative;
  top: -2px;
}

/*<!---------single event------->*/
.containers {
  width: 640px;
  padding: 0;
}

img.amsterdam {
  width: 24px;
  height: 24px;
}

img.Rotterdam {
  width: 24px;
  height: 24px;
}

input#date {
  text-transform: inherit;
}

.lg input {
  /* text-transform: uppercase; */
  color: #b2a6ad;
  font-family: Test National 2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.lg input::placeholder {
  text-transform: none;
}

/***slider**/
h2.binnenkort {
  color: #fff;
  font-family: Public Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.5px;
}

p.text-white.text-p {
  color: #f2ebef;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
}

.bottom-navbar {
  border-top: 1px solid var(--night-color-neutral-600, #4E4759);
  background: var(--night-color-neutral-1000, #0A0315);
}

.slider {
  width: 100%;
  margin: auto;
}

.slider-item:hover {
  background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.15) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.13);
}

.slider-item {
  /* height: 298px !important;
  background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.08) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.06);
  border: 1px solid rgba(175, 147, 255, 0.20);
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  flex-direction: column;
  transition: all 0.5s; */

  width: 100%;
  transition: all 0.5s;
  border: 1px solid rgba(175, 147, 255, 0.50) !important;
  border-radius: 8px !important;
  background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.08) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.06);
}

.slider-item .section-text {
  margin-top: 0;
}

.slider-item img {
  /* max-width: 100%; */
  /* max-height: 186px;
  height: 186px; */
  /* width: 100%; */
  object-fit: cover;
}

.swiper {
  padding: 5px !important;
}

.purple-bg {
  border-radius: 8px 8px 0px 0px;
  background: lightgray -20.064px -0.048px / 111.733% 100.077% no-repeat;
}

.custom-arrow .swiper-button-prev {
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.custom-arrow .swiper-button-prev::after {
  content: url('assets/image\ svg/right-arrow.svg');
  transform: rotate(180deg);
  width: 24px;
  height: 52px;
}

.custom-arrow .swiper-button-next {
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.custom-arrow .swiper-button-next::after {
  content: url('assets/image\ svg/right-arrow.svg');
  width: 24px;
  height: 52px;
}

img.Notification-img {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #fff;
  padding: 6px;
  border-radius: 50px;
  font-size: 43px;
  height: 36px;
  width: 35px;
  cursor: pointer;
  margin-right: 15px;
}

.slick-initialized .slick-slide {
  display: block;
  position: relative;
  margin: 8px;
  border-radius: 8px;
}

.slick-slide img {
  display: block;
  width: 100%;
  /* height: 164px; */
}

h5.slider-heading {
  color: #fff;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
}

p.slider-p {
  color: #fff;
  font-family: Public Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}

.tooltip span {
  border-radius: 4px;
  background: #5559df;
  padding: 2px 4px;
  align-items: flex-start;
  color: #eeeefc;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.tooltip {
  margin-top: 8px;
}

.section-text {
  padding: 16px;
}

.slick-slider .element {
  height: 100px !important;
  background-color: #000 !important;
  border-radius: 5px !important;
  display: inline-block !important;
  margin: 0px 10px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  font-size: 20px;
}

.slick-slider .element img {
  width: 100%;
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

/***slider**/
.slick-slider {
  margin-bottom: 44px;
}

.slick-next {
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  width: 40px;
  /* Adjust the width as needed */
  height: 40px;
  /* Adjust the height as needed */
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}

.slick-next img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

button.slick-prev {
  margin-left: 7px;
}

.slick-prev img {
  padding-top: 2px;
  height: 22px;
  width: 22px;
}

.slick-prev {
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}

.slick-next {
  right: 7px;
}

.slick-next img {
  position: relative;
  right: -7px;
}

/*popup single*/
.popups {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup-contents {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 31%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  background: linear-gradient(152deg,
      rgb(40 31 46) 53%,
      rgb(69 39 91) 100%,
      rgb(25 4 50) 100%);
  display: inline-flex;
  padding: 16px 40px 40px 40px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  z-index: 9999999999;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

p.Hier {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -1px;
}

p.Je {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

a.Terug {
  border-radius: 8px;
  border: 1px solid #8932f6;
  background: linear-gradient(180deg, #8932f6 0%, #a15bf9 100%);
  display: flex;
  height: 54px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-size: 16px;
  margin-top: 16px;
}

/*slider Modal */
.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

/* .close {
      display: none;
    } */
.modal.open {
  display: flex;
}

.modal-content {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  max-width: 440px;
  width: 440px;
  border-radius: 16px;
  border-radius: 16px;
  background: var(--night-color-primary-1000, #1C0A33) !important;
  /* backdrop-filter: blur(75px);  */
}

.close-button {
  float: right;
  width: 1.5rem;
  font-size: 31px;
  line-height: 21px;
  /* padding: 0 0.2em 0.15em; */
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: var(--clr-neutral);
  font-weight: 100;
  color: #fff;
  transition: color 0.12s ease-in-out;
  margin-right: 2px;
  margin-left: 8px;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.Modal-Body input[type='email'] {
  color: #e5dfe2;
  font-family: Test National 2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  height: 44px;
  padding: 12px;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #998e94;
  background: #262123;
  align-self: stretch;
  margin: 16px 0;
  width: 100%;
}

.Modal-Body h2 {
  color: #fff;
  text-align: center;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.Modal-Body p {
  color: #fff;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.modal-content .header {
  padding: 24px 24px 16px 24px;
  border-bottom: 1px solid #80737a;
}

.Modal-Body {
  padding: 24px 24px 24px 24px;
}

.modal-content body p {
  color: #fff;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

p.sturen {
  color: #E7D6FD;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 16px;
}

button.openFirstModal.btn.trigger:focus {
  background: none;
  border: none;
  outline: none;
}

button.btn.trigger {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 16px;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

/* button.btn.trigger { */
/* position: absolute; */
/* top: 16px; */
/* right: 15px; */
/* background: #fff; */
/* padding: 6px; */
/* border-radius: 50px; */
/* font-size: 43px; */
/* height: 32px; */
/* width: 32px; */
/* border: 0; */
/* cursor: pointer; */
/* } */
.Stuur,
.button.Stuur {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #8932f6;
  background: linear-gradient(180deg, #8932f6 0%, #a15bf9 100%);
  color: #fff;
  text-align: center;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
}

.close-button:hover {
  color: #fff;
}

a.openSecondModal.Stuur:hover {
  color: #fff;
}

.header h2 {
  color: #fff;
  text-align: center;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: normal;
}

.thnaks-img {
  text-align: center;
  padding-bottom: 20px;
}

p.gelukt {
  color: #fff;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 16px;
}

/*dropdown*/
/* Your CSS styles for dropdowns here */
.checkbox-dropdown {
  margin-right: 12px;
  display: inline-block;
}

.rowss {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.nextButton {
  position: relative;
  margin: 0 auto;
  user-select: none;
  min-width: 121px;
  color: #f2ebef;
  text-align: center;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: #262123 !important;
  cursor: pointer;
  box-shadow: none;
  border: none;
  cursor: pointer;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #80737a;
  background: #271446;
}

.checkbox-dropdown-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  display: none;
  background-color: #fff;
  border: 1px solid #80737a;
  z-index: 1;
  border-radius: 5px;
  margin-top: 15px;
  white-space: nowrap;
}

.checkbox-dropdown-list li {
  border: 1px solid #80737a;
  background: #1a0d2f;

  transition: all 0.2s ease-out;
}

.checkbox-dropdown-list li label {
  cursor: pointer;
  color: #fff;
}

.wrapper ul.checkbox-dropdown-list.border-none input {
  /* -webkit-appearance: none; */
  width: 19px;
  vertical-align: middle;
  padding: 0;
  margin: -3px 2px 0 0;
  background-color: #1a0d2f;
  border: 0px solid #51494d;
  border-radius: 4px;
  position: relative;
  margin-right: 8px;
}

.wrapper ul.checkbox-dropdown-list input {
  /*-webkit-appearance: none; */
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: -3px 2px 0 0;
  background-color: #1a0d2f;
  border: 2px solid #51494d;
  border-radius: 4px;
  position: relative;
  margin-right: 8px;
}

.wrapper ul.checkbox-dropdown-list input[type='checkbox']:checked::after {
  content: '';
  height: 5px;
  width: 10px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  display: none;
  position: absolute;
  top: 5px;
  left: 3px;
  transform: rotate(-45deg);
}

.wrapper ul.checkbox-dropdown-list input[type='checkbox']:checked::after {
  display: block;
}

.dropdown-menu {
  border: 1px solid #80737a !important;
  background: #1a0d2f !important;
  padding: 0 !important;
}

ul.dropdown-menu.checkbox-dropdown-list,
ul.dropdown-menu.checkbox-dropdown-list.border-none {
  margin-top: 18px;
}

ul.checkbox-dropdown-list li {
  display: block;

  border: 1px solid #80737a;
  background: #1a0d2f;
  transition: all 0.2s ease-out;
  color: #fff;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

ul.checkbox-dropdown-list label {
  text-align: center;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  width: 100%;
  text-align: left;
  padding: 8px 16px 8px 8px;
  margin: 0;
}

ul.checkbox-dropdown-list.border-none input {
  display: none;
}

.checkbox-custom,
.radio-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.evenementen ul.checkbox-dropdown-list.border-none input {
  display: none;
}

.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}

.checkbox-custom+.checkbox-custom-label:before,
.radio-custom+.radio-custom-label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 20px;
  margin-right: 8px;
  text-align: center;
}

.checkbox-custom:checked+.checkbox-custom-label:before {
  content: '\f00c';
  font-family: 'FontAwesome';
  background: rebeccapurple;
  color: #fff;
}

.radio-custom+.radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked+.radio-custom-label:before {
  content: '\f00c';
  font-family: 'FontAwesome';
  color: #fff;
  font-size: 13px;
}

.dropdown.open button.nextButton.btn.btn-dropdown.dropdown-toggle {
  border: 2px solid #80737a !important;
  background: #1a0d2f !important;
}

/* .btn-dropdown:hover {
  border-color: transparent !important;
} */
/*dropdown End */

/* menu style */
.menu-list .chakra-menu__group {
  border: 1px solid #80737a !important;
  background: #1a0d2f !important;
  padding: 0 !important;
  min-width: 165px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  color: #fff;
  border-radius: 4px;
}

.menu-list .chakra-menu__group>button {
  border: 1px solid #80737a;
  background: #1a0d2f;
  transition: all 0.2s ease-out;
  color: #fff;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 8px 16px 8px 8px;
}

.menu-checkbox-list .chakra-menu__group>button>.chakra-menu__icon-wrapper {
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #1a0d2f;
  border: 2px solid #51494d;
  opacity: 1;
}

.menu-checkbox-list .chakra-menu__group>button[aria-checked='true']>.chakra-menu__icon-wrapper>svg {
  margin: 3px 0px 2px 2px;
  visibility: visible;
}

.menu-checkbox-list .chakra-menu__group>button[aria-checked='false']>.chakra-menu__icon-wrapper>svg {
  visibility: hidden;
}

.checkbox-menu-list {
  border: 1px solid #80737a !important;
  background: #1a0d2f !important;
  padding: 0 !important;
  min-width: 160px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  color: #fff;
  border-radius: 4px;
}

.checkbox-menu-list>button {
  border: 1px solid #80737a;
  background: #1a0d2f;
  transition: all 0.2s ease-out;
  color: #fff;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 8px 16px 8px 8px;
}

/*tooltp*/

/*tooltip End--*/

.tooltips {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltips .tooltiptext {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 21px;
}

.zoek-evenementen .event-content span.tooltips {
  margin: 0;
  padding: 0px;
}

.zoek-evenementen .event-content span.tooltips svg {
  position: relative;
  left: -1px;
}

.tooltips:hover .tooltiptext {
  visibility: visible;
}

.tool {
  display: flex;
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  background-color: #5559df;
  color: #eeeefc;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 2px 4px;
  align-items: center;
  margin: 0 3px;
}

.zoek-evenementen .event-content span.gen {
  padding: 0;
}

/*tooltip End--*/
.external-dropdown {
  position: relative;
  /* z-index: 999 !important; */
}

.event-information-form form input.error-field,
select#geslacht.error-field,
.event-information-form form .checkbox.error-field input,
.error-field .flatpickr-input {
  border-color: red;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-size: 1.2rem !important;
}

.input-wrapper {
  position: relative;
}

.email-error {
  position: absolute;
  top: 100%;
  color: red;
  font-size: 16px;
  /* padding-top: 10px; */
  padding-left: 5px;
  left: 50%;
  transform: translate(-50%, 5px);
  width: 100%;
}

.email-form {
  position: relative;
}

.email-form .error-field {
  border-color: red;
}

.email-form .success-field {
  border-color: green;
}

@media (max-width: 480px) {
  .event-price-box>.soldout>.price-box {
    font-size: 14px;
  }
}

@media (max-width: 580px) {

  .filter-genre>.css-0,
  .filter-age>.css-0 {
    min-width: 160px !important;
  }
}

/* .slides-group .swiper-wrapper {
  margin-left: 80px !important;
} */

.slider-event-img {
  min-height: 168px;
}

.event-page .container-single {
  padding: 75px 150px;
  padding-bottom: 81px;
}

.event-page .event-information-box {
  padding: 3px 0px;
}

.event-page .event-price-box {
  padding: 24px;
  padding-right: 29px;
  margin: 0 auto;
  margin-top: 24px;
  border-radius: 8px;
  width: 100%;
}

.event-page .event-information-form {
  /*   max-width: 596px; */
  margin: 0 auto;
  margin-top: 48px;
  margin-bottom: 141px;
}

.event-page .event-information-form .event-price-box {
  width: 100%;
}

.copy-icon {
  width: 20px;
}

.event-code,
.event-code__span {
  display: flex;
  gap: 10px;
}

.cx-ui-select {
  width: 100%;
}

.cx-ui-select option {
  width: 100%;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .cx-ui-select {
    width: 100% !important;
  }

  .cx-ui-select option {
    width: 100% !important;
  }
}

@keyframes preloader {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.tab.preloader-events .tab-header .date,
.tab.preloader-events .tab-header .days {
  position: relative;
  border-radius: 10px;
  padding: 0px;
}

.tab.preloader-events .event-box .event-content {
  position: relative;
  border-radius: 10px;
}

.tab.preloader-events .tab-header .date::after,
.tab.preloader-events .tab-header .days::after,
.tab.preloader-events .event-box .event-content::after {
  content: '';
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  animation-name: preloader;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%); */
  /* background: linear-gradient(to right, #cccccc 8%, #bbbbbb 18%, #cccccc 33%); */
  background: linear-gradient(to right, #262123 8%, #363336 18%, #262123 33%);
  background-color: #000;
  background-size: 800px 100px;
}


.language-selector {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 1000000;
  color: white;
  font-size: 20px;
  background-color: transparent;
}

@media screen and (max-width: 800px) {
  .language-selector {
    right: 20px;
    top: 20px;
    font-size: 20px;
  }
}

select::-ms-expand {
  display: none !important;
}

.hero-card {
  border: 1px solid rgba(175, 147, 255, 0.20);
  background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.08) 0%, rgba(85, 75, 106, 0.04) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.06);
}

.dark-input {
  @apply bg-transparent border border-neutral-600 focus:outline-none p-4 rounded-[8px] text-[16px] text-white;
}

select.dark-input {
  @apply text-black bg-white;
}

.dark-checkbox {
  @apply !p-3 !text-[20px] focus:!outline-none;
}

.event-content.trending-event {
  border: 1px solid rgba(175, 147, 255, 0.8) !important;
  /* background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.15) 0%, rgba(85, 75, 106, 0.06) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.13); */
  box-shadow: 0 0 10px rgba(175, 147, 255, 0.2);
}

.event-content.trending-event:hover {
  /* background: radial-gradient(94.44% 100.01% at 51.51% 1.66%, rgba(194, 165, 255, 0.25) 0%, rgba(85, 75, 106, 0.08) 62.15%, rgba(0, 0, 0, 0.00) 100%), rgba(175, 147, 255, 0.18); */
  border: 1px solid rgba(175, 147, 255, 0.9) !important;
}

/* Keep your existing custom-scrollbar class for specific elements */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(26, 13, 47, 0.5);
  border-radius: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(175, 147, 255, 0.5);
  border-radius: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(175, 147, 255, 0.8);
}


.css-1t6s810 {
  z-index: 100000000000 !important;
}

.external-dropdown {
  position: relative;
  z-index: 1000001; /* Higher than hero section's 10000 */
}

.outer {
  position: relative;
  z-index: 1000001;
}

/* Add this to the end of your App.css file */
@supports (-webkit-touch-callout: none) {
  /* iOS specific styles */
  .menu-list {
    position: relative !important;
    z-index: 9999999 !important;
    transform: translateZ(0) !important;
    -webkit-transform: translateZ(0) !important;
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;
  }

  .chakra-menu__menu-list {
    position: relative !important;
    z-index: 9999999 !important;
  }

  .external-dropdown {
    isolation: isolate;
    z-index: 9999999 !important;
  }

  .hero-section {
    z-index: 0 !important;
  }

  .hero-section-single {
    z-index: 0 !important;
  }
}

/* Safari-specific fixes */
.safari-browser .menu-list,
.safari-browser .chakra-menu__menu-list {
  position: fixed !important;
  z-index: 9999999 !important;
}

.safari-browser .hero-section,
.safari-browser .hero-section-single {
  z-index: 0 !important;
}


/* Spin & Win styles */
.spin-win-event-card {
  transition: transform 0.3s ease;
}

.spin-win-event-card:hover {
  transform: translateY(-5px);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 4s cubic-bezier(0.17, 0.67, 0.26, 0.98);
}

.good-list {
  list-style-type: disc !important;
  margin-left: 20px;
}

.good-list li {
  list-style: disc !important;
}
